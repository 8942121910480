import React, { Component } from 'react';

export default class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                {/* footer ================================================== */}
                <footer>
                    <div className="row">
                        <div className="twelve columns">
                            <ul className="social-links">
                                <li><a target="_blank" rel="noopener noreferrer" href="http://linkedin.com/in/rodoliveira"><i className="fa fa-linkedin" /></a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://github.com/jrodolfo"><i className="fa fa-github" /></a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://www.flickr.com/photos/jrodolfo/sets/72157662674803713"><i className="fa fa-instagram" /></a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/jrodolfo"><i className="fa fa-facebook" /></a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="http://twitter.com/jrodolfo"><i className="fa fa-twitter" /></a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="http://gameknot.com/stats.pl?jrodolfo"><i className="fa fa-dribbble" /></a></li>
                            </ul>
                            <ul className="copyright">
                                <li>&copy; Copyright 2014-2024 CeeVee</li>
                                <li>Design by <a target="_blank" rel="noopener noreferrer" title="Styleshout" href="http://www.styleshout.com/">Styleshout</a></li>
                            </ul>
                            <ul className="copyright">
                                <li>&copy; Content Copyright 2004-2024 <a target="_blank" rel="noopener noreferrer" title="Styleshout" href="https://jrodolfo.net/">Rod Oliveira</a></li>
                                <li>Last edited on July 29, 2024</li>
                            </ul>
                        </div>
                        <div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i className="icon-up-open" /></a></div>
                    </div>
                </footer> {/* Footer End*/}
            </React.Fragment>
        );
    }
}
